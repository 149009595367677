import RecaptchaEmptySVG from "assets/svg/recaptcha-empty-svg";

const defaultStyle = {
  height: "calc(100vh - 213px)",
};

const EmptyReCaptcha = () => {
  return (
    <div className="grid h-full" style={defaultStyle}>
      <div className="m-auto">
        <RecaptchaEmptySVG className="m-auto w-52 h-52" />
        <div className="text-center">
          <p className="pt-6 text-xl font-medium">No reCAPTCHA keys yet</p>
          <p className="pt-2 text-sm">
            <span className="opacity-70">
              Add your first reCAPTCHA key today!
            </span>
          </p>
        </div>
      </div>
    </div>
  );
};

export default EmptyReCaptcha;
