import React from "react";
import _ from "lodash";
import Pagination from "react-responsive-pagination";
import LoadingIndicator from "assets/svg/loading-indicator";

const thClass =
  "px-5 py-3 bg-thBg font-normal text-sm text-black text-opacity-70 ";
const tdClass = "p-5 border-b border-thBorder ";

const LoadingComponent = () => {
  return (
    <div
      className="flex items-center justify-center"
      style={{ height: "65vh" }}
    >
      <LoadingIndicator className="w-10 h-10 text-secondary" />
    </div>
  );
};

export default function RecaptchaTable({
  columns = [],
  dataSource = [],
  noData,
  paginate = false,
  pagination = {},
  loading = false,
}) {
  if (_.isEmpty(dataSource) && !loading) {
    return noData;
  } else {
    return (
      <div
        className="px-4 py-4 -mx-4 sm:-mx-8 sm:px-8"
        style={{ minWidth: "1024px" }}
      >
        {loading ? (
          <LoadingComponent />
        ) : (
          <div className="inline-block min-w-full shadow">
            <table className="min-w-full leading-normal campaign-table">
              <thead>
                <tr className="border border-thBorder">
                  {(columns || []).map((value) => {
                    let { title, key, width = "0px", align = "left" } = value;
                    return (
                      <th
                        key={key}
                        className={thClass + `text-${align}`}
                        width={width}
                      >
                        {title}
                      </th>
                    );
                  })}
                </tr>
              </thead>
              <tbody>
                {dataSource.map((record, index) => {
                  return (
                    <tr key={index} className="bg-white border border-thBorder">
                      {(columns || []).map((column) => {
                        let {
                          key,
                          align = "center",
                          dataIndex,
                          render,
                        } = column;
                        if (render) {
                          return (
                            <td key={key} className={tdClass + `text-${align}`}>
                              {render(record)}
                            </td>
                          );
                        } else {
                          return (
                            <td key={key} className={tdClass + `text-${align}`}>
                              <p className="text-base">{record[dataIndex]}</p>
                            </td>
                          );
                        }
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        )}
        {paginate && !_.isEmpty(dataSource) && (
          <div className="mt-4 w-full">
            <Pagination
              total={pagination.lastPage || 1}
              current={pagination.currentPage || 1}
              onPageChange={(page) => pagination.onChangePage(page)}
              previousLabel="Previous"
              nextLabel="Next"
            />
          </div>
        )}
      </div>
    );
  }
}
