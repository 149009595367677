import LoadingIndicator from "assets/svg/loading-indicator";
import moment from "moment";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import userActions from "redux/modules/user/actions";

const EmailVerification = ({ userData, setUserData }) => {
  const history = useHistory();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const token = urlSearchParams.get("requestToken");
  const { http, localstorage } = global.services;
  const [state, setState] = useState({
    loading: false,
    message: "",
  });

  const addMonths = (numOfMonths, date = new Date()) => {
    date.setMonth(date.getMonth() + numOfMonths);

    return date;
  };

  const initialize = async () => {
    try {
      if (!token) return history.replace("/dashboard");

      const res = await http.post("auth/verify-email", { token });
      const data = {
        ...userData,
        access_token: res.data.access_token,
        token_type: "bearer",
        refresh_ttl_expires: moment(addMonths(1)).format("X"),
        profile: res.data.data.profile,
        user: res.data.data.user,
      };

      localstorage.set("user_data", JSON.stringify(data));
      setUserData(data);
      toast.success("Successfully verified email");
      setState((prev) => ({ ...prev, loading: false, message: "" }));
      localstorage.remove("verification_token");
    } catch (error) {
      console.error(error);
      let errorMsg =
        error?.data?.message ||
        "An unknown error occured. Please refresh the page and try again.";
      toast.error(errorMsg);
    } finally {
      setState((prev) => ({
        ...prev,
        loading: false,
        message: "Redirecting to dashboard",
      }));
      setTimeout(() => {
        // hard refresh page to reset state.
        window.location.href = "/dashboard";
      });
    }
  };

  const handleTrialLinkCode = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const trialCode = queryParameters.get("xt") ?? null;
    const trialDays = queryParameters.get("td") ?? 0;

    if (trialCode && trialDays > 0) {
      localstorage.set("trialCode", trialCode);
      localstorage.set("trialDays", trialDays);
    }
  };

  useEffect(() => {
    setState((prev) => ({ ...prev, loading: true }));
    handleTrialLinkCode();
    initialize();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [http]);

  return (
    <div className="h-screen bg-secondary text-white text-2xl flex items-center justify-center flex-col space-y-3">
      <LoadingIndicator className="text-white w-6 h-6" />
      {token && state.loading ? (
        <p>Verifying your email</p>
      ) : (
        <p>{state.message}</p>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userData: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(EmailVerification);
