import React, { useEffect, useState } from "react";
import Modal from "components/global/modal";
import Button from "components/button/button";
import Input from "components/input/input";
import _ from "lodash";
import SelectPopout from "components/select/select-popout";
import toast from "react-hot-toast";
import validator from "validator";
import LoadingIndicator from "assets/svg/loading-indicator";
import Select from "components/select/select";
import { IoInformationCircleOutline } from "react-icons/io5";
import { removeTrailingSlash } from "helpers/stringConverter";

export default function CampaignCreate({ hide, ...props }) {
  const { http } = global.services;
  const [selected, setSelected] = useState(
    props?.campaign_popups?.[0]?.popup_a
  );
  const [list, setList] = useState([]);
  const [captchaList, setCaptchaList] = useState([]);
  const [captcha, setCaptcha] = useState({
    label: props.captcha ? props.captcha?.name : "",
    value: props.captcha ? props.captcha?.id : "",
  });
  const [loading, setLoading] = useState(false);
  const [state, setState] = useState({
    title: props?.title ? props.title : "",
    website_url: props?.website_url ? props.website_url : "",
  });
  const [formError, setFormError] = useState({});

  const getPopoutList = async () => {
    try {
      const result = await http.get(`popups`, {
        limit: 1000,
      });
      const data = result.data.data;
      if (data) {
        setList(data);
        if (props?.type === "create") {
          setSelected(data[0]); //set selected first by default
        }
      }
    } catch (error) {
      if (error.status === 422)
        toast.error("It appears that something went wrong");
    }
  };

  const getReCaptchaList = async () => {
    try {
      const result = await http.get(`captchas`, {
        limit: 100,
      });

      const data = result.data.data;
      if (data) {
        let payload = [];
        data.forEach((obj) => {
          payload.push({ label: obj.name, value: obj.id });
        });
        setCaptchaList(payload);
      }
    } catch (error) {
      if (error.status === 422)
        toast.error("It appears that something went wrong");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => ({ ...prev, [name]: value }));
    setFormError((prev) => ({ ...prev, [name]: null }));
  };

  const validateUrl = (e) => {
    const { name, value } = e.target;
    if (name === "website_url") {
      if (!validator.isURL(value)) {
        setFormError((prev) => ({
          ...prev,
          [name]: "The URL you entered is invalid.",
        }));
      }
    } else {
      if (_.isEmpty(value)) {
        setFormError((prev) => ({
          ...prev,
          [name]: "Title is required",
        }));
      }
    }
  };

  const handleSubmit = async () => {
    const payload = _.clone(state);
    const url = _.toLower(state.website_url).includes("http")
      ? _.replace(state.website_url, "http", "https")
      : `https://${state.website_url}`;
    payload.popup_id = selected.id;
    payload.website_url = _.toLower(state.website_url).includes("https")
      ? removeTrailingSlash(state.website_url)
      : removeTrailingSlash(url);
    payload.captcha_id = captcha?.value || null;

    if (props?.type === "create") payload.status = "dormant";
    if (props?.id) payload.id = props.id;

    if (!_.isEmpty(payload.title) && validator.isURL(payload.website_url)) {
      setLoading(true);
      try {
        if (props?.type === "create") {
          const result = await http.post(`campaigns`, payload);
          let data = result.data.data;
          if (data) {
            data.message = "Campaign successfully created";
            hide(data);
          }
        } else {
          const result = await http.put(`campaigns/${props.id}`, payload);
          let data = result.data.data;
          if (data) {
            data.message = "Campaign successfully updated";
            hide(data);
          }
        }
      } catch (error) {
        if (error.data?.message) toast.error(error.data.message);

        if (error.status === 422)
          toast.error("It appears that something went wrong");
      } finally {
        setLoading(false);
      }
    } else {
      validateUrl({ target: { name: "title", value: payload.title } });
      validateUrl({
        target: { name: "website_url", value: payload.website_url },
      });
    }
  };

  useEffect(() => {
    getPopoutList();
    getReCaptchaList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const Footer = () => {
    return (
      <div className="relative flex flex-row items-center justify-end w-8/12 space-x-2">
        <Button
          key="cancel-btn"
          buttonName="CANCEL"
          buttonType="default"
          buttonClass="bg-default rounded-full py-3 px-9"
          buttonTextClass="text-black text-sm font-bold"
          onClick={() => hide()}
        />
        <Button
          key="save-btn"
          buttonName={props?.id ? "SAVE CHANGES" : "CREATE CAMPAIGN"}
          buttonClass="bg-primary rounded-full py-3 px-12"
          buttonTextClass="text-white text-sm font-bold"
          onClick={handleSubmit}
          buttonType="primary"
          disabled={loading || _.isEmpty(selected)}
          icon={
            loading && (
              <div className="absolute right-3 bottom-0 top-0 flex items-center">
                <LoadingIndicator className="text-white w-5 h-5" />
              </div>
            )
          }
        />
      </div>
    );
  };

  return (
    <Modal
      footer={<Footer />}
      width="40%"
      contentClassName="rounded-lg"
      wrapperClassName="overflow-visible"
      footerClass="border-t border-tabBorder"
    >
      <div className="flex items-start px-4">
        <div className="w-full">
          <h3 className="text-2xl leading-9" id="modal-title">
            {props?.id ? "Edit Campaign Details" : "Create Campaign"}
          </h3>
          <div className="mt-4 relative">
            <div className="grid grid-cols-2">
              <Input
                inputContainerClass="input-container"
                value={state.title}
                outsideLabel={"Title"}
                labelClass="mb-5"
                placeholder="My First Campaign"
                name="title"
                maxLength={100}
                type="text"
                onChange={handleChange}
                onBlur={validateUrl}
                errorMessage={formError?.title}
                errorMessagePosition="bottom"
              />
              <Input
                inputContainerClass="input-container"
                value={state.website_url}
                outsideLabel="Campaign website URL"
                labelClass="mb-5"
                placeholder="www."
                name="website_url"
                type="text"
                onChange={handleChange}
                onBlur={validateUrl}
                errorMessage={formError?.website_url}
                errorMessagePosition="bottom"
              />
            </div>
            <div>
              <SelectPopout
                label="Choose the popout"
                name="popout"
                options={list}
                value={selected}
                onChange={(value) => setSelected(value)}
              />
            </div>
            <div className="w-full mt-4">
              <Select
                label="reCAPTCHA (Optional)"
                subText="We highly recommend that you us recapture to help reduce spam"
                options={captchaList}
                emptyPlaceholder={
                  <div className="w-full text-center">
                    <p className="text-xl flex items-center justify-center">
                      <IoInformationCircleOutline className="text-3xl mr-2 opacity-70" />
                      <span>No reCAPTCHA keys yet</span>
                    </p>
                    <p className="text-sm">
                      <span className="text-black text-opacity-70">
                        Do you want to add your first reCAPTCHA?
                      </span>
                      &nbsp;
                      <a className="text-secondary" href="/recaptcha">
                        Add Now
                      </a>
                    </p>
                  </div>
                }
                value={captcha?.label}
                placeholder="Select reCAPTCHA"
                onChange={(e) => setCaptcha(e)}
              />
            </div>
          </div>
        </div>

        <span
          className="close absolute top-6 right-8"
          onClick={() => hide()}
        ></span>
      </div>
    </Modal>
  );
}
