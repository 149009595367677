import Button from "components/button/button";
import WYSIWYGEditorDraft, {
  converToEditorState,
} from "components/wysiwyg/wysiwyg-editor";
import { Context } from "context/context";
import update from "immutability-helper";
import _ from "lodash";
import { useContext, useEffect, useMemo, useState } from "react";
import { geocodeByPlaceId } from "react-google-places-autocomplete";
import toast from "react-hot-toast";
import ReactPlayer from "react-player";
import {
  CONTACTOBJ,
  MAX_TITLE_LENGTH,
  ENQUIRYFORMSUGGESTIONS,
  sanitizeValueHTML,
} from "utils/constant";
import { DisplayErrorMessage } from "utils/utils";
import validator from "validator";
import SuggestionButton from "../add-content/component/suggestions-btn";
import StandardDetailsTab from "./component/standard-details-tab";

export default function StandardDetails({
  handleChangeStep,
  state,
  setState,
  handleOnSave,
}) {
  const {
    setSelectedTab,
    popup,
    disabledStylePopout,
    setDisabledStylePopout,
    setPopup,
  } = useContext(Context);
  const { http } = global.services;
  const initials = useMemo(() => {
    if (_.isEmpty(popup?.contact_method_content)) {
      return _.find(CONTACTOBJ, { type: "standard-details" });
    } else {
      return JSON.parse(popup?.contact_method_content);
    }
  }, [popup.contact_method_content]);
  // start wysiwyg
  const titleInitials = useMemo(() => {
    let data = {};
    if (_.isEmpty(popup?.contact_method_content)) {
      data = _.find(CONTACTOBJ, { type: "standard-details" });
    } else {
      data = JSON.parse(popup?.contact_method_content);
    }
    return converToEditorState(data.title || "");
  }, [popup.contact_method_content]);
  const [editorState, setEditorState] = useState(titleInitials);
  // end wysiwyg
  const [content, setContent] = useState(initials);
  const [suggestion, setSuggestion] = useState(0);
  const [imageVideo, setImageVideo] = useState(null);

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    if (name === "video_link") {
      setDisabledStylePopout([]);

      setContent((prev) => ({
        ...prev,
        [name]: value,
      }));
    } else {
      setContent((prev) => ({
        ...prev,
        [name]: value,
      }));
    }

    setState((prev) => ({
      ...prev,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const handleInputWithObjectChange = (e, key) => {
    if (e.target) {
      const { name, value } = e.target;
      content[name][key] = value;

      if (state.validationErrors && state.validationErrors[name])
        state.validationErrors[name][key][0] = null;
    }

    if (!e.target) {
      content[e][key] = !content[e].visible;
    }

    if (!e.target && e === "address" && key === "visible") {
      let payload = content[e];
      let obj = update(payload, {
        pin: { $set: "" },
        latitude: { $set: 0 },
        longitude: { $set: 0 },
      });
      content[e] = obj;
    }

    setContent({ ...content });
    setState({ ...state });
  };

  const handleInputWithObjectChangeAddress = async (value, key) => {
    const geocodePlaceID = await geocodeByPlaceId(value.value.place_id);
    const payload = _.clone(content);
    let obj = update(payload, {
      address: {
        [key]: { $set: value.label },
        latitude: { $set: geocodePlaceID[0].geometry.location.lat() },
        longitude: { $set: geocodePlaceID[0].geometry.location.lng() },
      },
    });

    if (state.validationErrors?.address)
      state.validationErrors.address[key][0] = null;

    setContent(obj);
    setState({ ...state });
    setPopup((prev) => ({
      ...prev,
      contact_method_content: JSON.stringify(content),
    }));
  };

  //validation
  const validateEmail = (email) => {
    if (validator.isEmail(email)) {
      return "";
    } else {
      setState((prev) => ({
        ...prev,
        validationErrors: {
          ...prev.validationErrors,
          email_address: {
            value: ["The Email Address must be a valid email address."],
          },
        },
      }));
      return "The Email Address must be a valid email address.";
    }
  };

  const validateContactNumber = (number) => {
    if (_.isEmpty(_.trim(number))) {
      setState((prev) => ({
        ...prev,
        validationErrors: {
          ...prev.validationErrors,
          contact_number: {
            value: ["The Contact Number format is invalid."],
          },
        },
      }));
      return "The Contact Number format is invalid.";
    } else {
      if (number.match(/^[0-9 ()+]+$/)) {
        if (_.size(number) >= 3) {
          return "";
        } else {
          setState((prev) => ({
            ...prev,
            validationErrors: {
              ...prev.validationErrors,
              contact_number: {
                value: ["The Contact Number must be at least 3 characters"],
              },
            },
          }));
          return "The Contact Number must be at least 3 characters";
        }
      } else {
        setState((prev) => ({
          ...prev,
          validationErrors: {
            ...prev.validationErrors,
            contact_number: {
              value: ["The Contact Number format is invalid."],
            },
          },
        }));
        return "The Contact Number must be at least 3 characters";
      }
    }
  };

  const validateAddress = (address) => {
    if (_.size(address) >= 3) {
      return "";
    } else {
      setState((prev) => ({
        ...prev,
        validationErrors: {
          ...prev.validationErrors,
          address: {
            value: ["The Address must be at least 3 characters."],
          },
        },
      }));
      return "The Address must be at least 3 characters.";
    }
  };

  const validateAddressPin = (value) => {
    if (!_.isEmpty(value)) {
      return "";
    } else {
      setState((prev) => ({
        ...prev,
        validationErrors: {
          ...prev.validationErrors,
          pin: {
            value: ["The Address pin location is required."],
          },
        },
      }));
      return "The Address pin location is required.";
    }
  };

  const validateButton = async (obj, index) => {
    /**
     * If has button title but no URL added, then show validation error
     * And If has button URL but no title added, then show validation error
     */
    if ((obj.text && !obj.url) || (obj.url && !obj.text)) {
      let err =
        obj.text && !obj.url
          ? "A Button URL is required."
          : "A Button Title is required.";
      setState((prev) => ({
        ...prev,
        validationErrors: {
          ...prev.validationErrors,
          buttons: {
            ...prev.validationErrors?.buttons,
            [index]: {
              url: [err],
            },
          },
        },
      }));

      return err;
    }

    if (validator.isURL(obj.url)) {
      const res = await http.post("blacklist/check", {
        website_url: obj.url,
      });
      if (res.data.isBlackllisted) {
        setState((prev) => ({
          ...prev,
          validationErrors: {
            ...prev.validationErrors,
            buttons: {
              ...prev.validationErrors?.buttons,
              [index]: {
                url: ["The URL is blacklisted."],
              },
            },
          },
        }));
        return "The URL is blacklisted.";
      } else return "";
    } else {
      setState((prev) => ({
        ...prev,
        validationErrors: {
          ...prev.validationErrors,
          buttons: {
            ...prev.validationErrors?.buttons,
            [index]: {
              url: ["The Button format is invalid."],
            },
          },
        },
      }));
      return "The Button format is invalid.";
    }
  };

  const validateVideLink = (link) => {
    let error = null;
    if (validator.isURL(link)) {
      if (ReactPlayer.canPlay(link)) {
        return "";
      } else {
        error = "Video Link is not a valid video link";
      }
    } else {
      error = "Video Link is not a valid link";
    }

    if (error) {
      setState((prev) => ({
        ...prev,
        validationErrors: {
          ...prev.validationErrors,
          video_link: {
            value: [error],
          },
        },
      }));
      return error;
    }
  };

  const validateBeforeSave = (content) => {
    let fields = ["contact_number", "email_address", "address", "video_link"];
    let email_address = false;
    let contact_number = false;
    let address = false;
    let video_link = false;
    let err = [];
    setDisabledStylePopout(err);
    _.forEach(content, async (obj, key) => {
      if (fields.includes(key)) {
        if (content[key]?.value) {
          if (key === "email_address") {
            let resultValidate = validateEmail(obj.value);
            if (_.isEmpty(resultValidate)) {
              email_address = true;
            } else {
              err.push(resultValidate);
            }
          }
          if (key === "contact_number") {
            let resultValidate = validateContactNumber(obj.value);
            if (_.isEmpty(resultValidate)) {
              contact_number = true;
            } else {
              err.push(resultValidate);
            }
          }
          if (key === "address") {
            let resultValidate = validateAddress(obj.value);
            if (_.isEmpty(resultValidate)) {
              address = true;
            } else {
              err.push(resultValidate);
            }
          }
        } else {
          //empty
          if (key === "email_address") {
            email_address = true;
          }
          if (key === "contact_number") {
            contact_number = true;
          }
          if (key === "address") {
            address = true;
          }
        }
        //video link
        if (key === "video_link") {
          if (content[key]) {
            let resultValidate = validateVideLink(obj);
            if (_.isEmpty(resultValidate)) {
              video_link = true;
            } else {
              err.push(resultValidate);
            }
          } else {
            video_link = true;
          }
        }
      }
    });

    let buttons = (content.buttons || []).map((obj, index) => {
      let result = false;
      if (obj.text || obj.url) {
        let resultValidate = validateButton(obj, index);
        if (_.isEmpty(resultValidate)) {
          result = true;
        } else {
          err.push(resultValidate);
        }
      } else {
        result = true;
      }
      return result;
    });

    if (email_address && contact_number && address && video_link) {
      if (buttons.every((element) => element)) {
        handleOnSave(content);
      } else {
        setDisabledStylePopout(err);
      }
    } else {
      setDisabledStylePopout(err);
    }
  };
  //validation end

  const onUploadImage = async (e) => {
    setState((prevState) => ({
      ...prevState,
      uploading: true,
    }));
    setImageVideo(null);
    const formData = new FormData();
    const file = e.target.files[0];

    if (file.size > 300000) {
      setState((prevState) => ({ ...prevState, uploading: false }));
      return toast.error("Oops! The file is too large. Max file size: 300KB");
    } else {
      /* Remove old image when attempting to re-upload new image */
      if (content.image_url && content.image_key && content.image_id) {
        await http.delete(`medias/${content.image_id}`, {
          key: content.image_key,
        });
        setState((prev) => ({ ...prev, media_id: null }));
      }
    }

    try {
      formData.append("file", file);
      formData.append("type", "contact-methods");
      const uploadResult = await http.post("medias", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      const image = uploadResult.data.data;
      setState((prev) => ({ ...prev, media_id: image.id }));
      setContent((prev) => ({
        ...prev,
        video_link: null,
        image_id: image.id,
        image_url: image.presigned_url,
        image_key: image.key,
        image_width: image.width,
        image_height: image.height,
      }));
    } catch (error) {
      toast.error(
        "An unknown error occured. Please refresh the page and try again."
      );
    } finally {
      e.target.value = "";
    }
  };

  const handleRemoveImage = async () => {
    setState((prevState) => ({
      ...prevState,
      uploading: true,
    }));
    setImageVideo(null);
    const payload = _.clone(content);
    try {
      if (content.image_url && content.image_key && content.image_id) {
        const removeImageResult = await http.delete(
          `medias/${content.image_id}`,
          {
            key: content.image_key,
          }
        );
        if (removeImageResult.data?.success) {
          setState((prev) => ({ ...prev, media_id: null }));
          payload.image_id = null;
          payload.image_url = null;
          payload.image_key = null;
          payload.image_width = null;
          payload.image_height = null;
          setContent(payload);
          handleOnSave(payload);
          toast.success("Image removed successfully");
        }
      }
    } catch (error) {
      if (error.status === 422) {
        toast.error("It appears that something went wrong");
      } else if (error.status === 404) {
        toast.error("It appears that something went wrong");
      }
    } finally {
      setState((prevState) => ({ ...prevState, uploading: false }));
    }
  };

  //suggesstions
  const handleSuggestion = (type) => {
    const title = ENQUIRYFORMSUGGESTIONS;
    const size = _.size(ENQUIRYFORMSUGGESTIONS) - 1;
    let value = null;
    if (type === "next") {
      if (suggestion > size) {
        setSuggestion(1);
        value = title[0];
      } else {
        value = title[suggestion];
        setSuggestion((prev) => prev + 1);
      }
    } else {
      let counter = suggestion - 2;
      if (suggestion === 0 || suggestion === 1) {
        value = title[size];
        setSuggestion(size);
      } else {
        if (counter > 0) {
          value = title[counter];
          setSuggestion((prev) => prev - 1);
        } else if (counter === 0) {
          value = title[counter];
          setSuggestion(size + 2);
        }
      }
    }
    if (value) {
      let payload = _.clone(content);
      let data = update(payload, {
        title: { $set: value },
      });
      //handle set
      setEditorState(converToEditorState(value));
      setContent(data);
      validateBeforeSave(data);
    }
  };

  //additional
  const handleChangeValue = (value, element) => {
    let payload = _.clone(content);
    let finalValue = value;
    let data = payload;
    if (element === "title") {
      if (_.isEmpty(_.trim(value.replace(/(<([^>]+)>)/gi, "")))) {
        finalValue = "";
        data = update(payload, {
          [element]: { $set: finalValue },
          title_decoration: { $set: "normal" },
        });
      } else {
        data = update(payload, {
          [element]: { $set: sanitizeValueHTML(finalValue) },
          title_decoration: { $set: "normal" },
        });
      }
    } else {
      data = update(payload, {
        [element]: { $set: finalValue },
      });
    }
    setState((prev) => ({
      ...prev,
      validationErrors: { ...state.validationErrors, [element]: null },
    }));
    setContent(data);
    //set to popout
    if (element === "title") {
      setPopup((prev) => ({
        ...prev,
        contact_method_content: JSON.stringify(data),
      }));
    }
  };
  //end addtional

  const validateBeforeNext = () => {
    if (!_.isEmpty(disabledStylePopout)) {
      return toast.error(<DisplayErrorMessage errors={disabledStylePopout} />);
    } else {
      if (content?.address?.value) {
        if (!content?.address?.visible) {
          handleChangeStep(content);
        } else {
          let res = validateAddressPin(content?.address?.pin);
          if (_.isEmpty(res)) {
            handleChangeStep(content);
          } else {
            return toast.error(res);
          }
        }
      } else {
        handleChangeStep(content);
      }
    }
  };

  useEffect(() => {
    if (state.uploading) handleOnSave(content);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content]);

  return (
    <>
      {/* header */}
      <div className="w-full">
        <p className="font-medium text-2xl">Complete Your Contact Details</p>
      </div>
      {/* end header */}
      <div className="w-full pt-5">
        {/* left card start */}
        <form
          onSubmit={(e) => {
            e.preventDefault();
            validateBeforeNext();
          }}
          className="w-full bg-white rounded-xl relative campaign-left-max mr-32"
          style={{ height: "fit-content" }}
        >
          <div className="p-5">
            <p className="text-xl pl-1 pb-2">Design your contact details</p>

            {/* middle */}
            <div>
              <p className="pl-1 pt-2 pb-2">Type your heading here:</p>
              {/* title */}
              <div className="mb-3">
                <WYSIWYGEditorDraft
                  placeholder={`e.g. Excepteur sint occaecat cupidatat non proident, sunt in cul...`}
                  onChange={(value) => {
                    handleChangeValue(value, "title");
                  }}
                  onBlur={() => validateBeforeSave(content)}
                  errorMessage={state.validationErrors?.title}
                  showCounter={true}
                  maxLength={MAX_TITLE_LENGTH}
                  editorState={editorState}
                  setEditorState={setEditorState}
                />
                <div className="mt-2 w-full flex items-center 5md:block 3lg:flex 3lg:items-center justify-between">
                  <SuggestionButton onClick={(e) => handleSuggestion(e)} />
                </div>
              </div>
              {/* tabs */}
              <StandardDetailsTab
                content={content}
                state={state}
                setContent={setContent}
                handleInputChange={handleInputChange}
                handleInputWithObjectChange={handleInputWithObjectChange}
                handleInputWithObjectChangeAddress={
                  handleInputWithObjectChangeAddress
                }
                onUploadImage={onUploadImage}
                handleRemoveImage={handleRemoveImage}
                onHandleBlur={() => validateBeforeSave(content)}
                imageVideo={imageVideo}
                setImageVideo={setImageVideo}
              />
            </div>
          </div>
          {/* footer */}
          <div className="border-t border-tabBorder">
            <div className="flex flex-row flex-1 justify-end p-5 space-x-10">
              <Button
                buttonName="PREVIOUS"
                buttonClass="bg-transparent rounded-full py-3"
                buttonType="transparent"
                buttonTextClass="text-sm font-bold"
                onClick={() => setSelectedTab("contactM")}
              />
              <Button
                buttonName="NEXT"
                buttonClass="relative bg-primary rounded-full py-3 px-12"
                buttonType="primary"
                type="submit"
              />
            </div>
          </div>
        </form>
      </div>
    </>
  );
}
