import Maintenance from "pages/maintenance/maintenance";
import Login from "pages/login/login";
import Account from "pages/account/account";
import SignUp from "pages/sign-up/sign-up";
import Dashboard from "pages/dashboard/dashboard";
import HowItWorks from "pages/how-it-works/how-it-works";
import About from "pages/about/about";
import ContactUs from "pages/contact-us/contact-us";
import PrivacyPolicy from "pages/privacy-policy/privacy-policy";
import TermsConditions from "pages/terms-conditions/terms-conditions";
import EmailVerification from "pages/email-verification/email-verification";
import NotFoundPage from "pages/not-found-page/not-found-page";
import ForgotPassword from "pages/forgot-password/forgot-password";
import PasswordReset from "pages/password-reset/password-reset";
import TwoColumnLayout from "layouts/two-column-layout";
import GoogleCallback from "pages/auth/google/callback";
import Campaign from "pages/campaigns/campaign";

import { Banner1 } from "constants/images";
import Contact from "pages/contacts/contact";
import Integration from "pages/integrations/Integration";
import Install from "pages/install/intall";
import CreateCampaign from "pages/campaigns/create";
import CampaignDetails from "pages/campaigns/details";
import Popouts from "pages/popouts/popout";
import PreviewCampaign from "pages/campaigns/preview";
import Guest from "pages/guest/guest";
import GrantAccess from "pages/grant-access/grant-access";
import SupportLogin from "pages/support-login/support-login";
import { Helmet } from "react-helmet";
import ReCaptchaPage from "pages/recaptcha/recaptcha";
import CampaignSpecialCondtion from "components/campaigns/details/alternative-page";

const routes = [
  {
    path: "/email-verification",
    authRequired: false,
    component: () => <EmailVerification />,
  },
  {
    path: "/forgot-password",
    authRequired: false,
    component: () => (
      <TwoColumnLayout imageBanner={Banner1}>
        <Helmet>
          <title>Forgot Password - ConversionCow</title>
        </Helmet>
        <ForgotPassword />
      </TwoColumnLayout>
    ),
  },
  {
    path: "/password-reset",
    authRequired: false,
    component: () => (
      <TwoColumnLayout imageBanner={Banner1}>
        <Helmet>
          <title>Password Reset - ConversionCow</title>
        </Helmet>
        <PasswordReset />
      </TwoColumnLayout>
    ),
  },
  {
    path: "/",
    authRequired: true,
    component: () => <Dashboard />,
  },
  {
    path: "/maintenance",
    authRequired: false,
    component: () => <Maintenance />,
  },
  {
    path: "/login",
    authRequired: false,
    component: () => (
      <TwoColumnLayout imageBanner={Banner1}>
        <Helmet>
          <title>Login - ConversionCow</title>
        </Helmet>
        <Login />
      </TwoColumnLayout>
    ),
  },
  {
    path: "/campaign-builder",
    authRequired: false,
    component: () => <Guest />,
  },
  {
    path: "/sign-up",
    authRequired: false,
    component: () => (
      <TwoColumnLayout imageBanner={Banner1}>
        <Helmet>
          <title>Sign Up - ConversionCow</title>
        </Helmet>
        <SignUp />
      </TwoColumnLayout>
    ),
  },
  {
    path: "/how-it-works",
    authRequired: false,
    component: () => <HowItWorks />,
  },
  {
    path: "/about",
    authRequired: false,
    component: () => <About />,
  },
  {
    path: "/privacy-policy",
    authRequired: false,
    component: () => <PrivacyPolicy />,
  },
  {
    path: "/terms-and-conditions",
    authRequired: false,
    component: () => <TermsConditions />,
  },
  {
    path: "/contact-us",
    authRequired: false,
    component: () => <ContactUs />,
  },
  {
    path: "/dashboard",
    authRequired: true,
    component: () => <Dashboard />,
  },
  {
    path: "/dashboard/account/:active_tab?",
    authRequired: true,
    component: () => <Account />,
  },
  {
    path: "/grant-access/:key",
    authRequired: false,
    component: () => <GrantAccess />,
  },
  {
    path: "/support-login/:key",
    authRequired: false,
    component: () => <SupportLogin />,
  },
  {
    path: "/campaigns",
    authRequired: true,
    component: () => <Campaign />,
  },
  {
    path: "/campaigns/popup",
    authRequired: true,
    component: () => <CreateCampaign />,
  },
  {
    path: "/campaigns/popup/:id",
    authRequired: true,
    component: () => <CreateCampaign />,
  },
  {
    path: "/campaigns/:cid/popup/:id",
    authRequired: true,
    component: () => <CreateCampaign />,
  },
  {
    path: "/campaigns/popup/preview/:id",
    authRequired: true,
    component: () => <PreviewCampaign />,
  },
  {
    path: "/campaigns/popup/preview/:id/:cid",
    authRequired: true,
    component: () => <PreviewCampaign />,
  },
  {
    path: "/campaign-details/:id/:active_tab?",
    authRequired: true,
    component: () => <CampaignDetails />,
  },
  {
    path: "/campaign-details/:id/manage/special-condition",
    authRequired: true,
    component: () => <CampaignSpecialCondtion />,
  },
  {
    path: "/popouts",
    authRequired: true,
    component: () => <Popouts />,
  },
  {
    path: "/popouts/:id",
    authRequired: true,
    component: () => <Popouts />,
  },
  {
    path: "/contacts",
    authRequired: true,
    component: () => <Contact />,
  },
  {
    path: "/integrations",
    authRequired: true,
    component: () => <Integration />,
  },
  {
    path: "/install",
    authRequired: true,
    component: () => <Install />,
  },
  {
    path: "/recaptcha",
    authRequired: true,
    component: () => <ReCaptchaPage />,
  },
  {
    path: "/auth/google/callback",
    component: () => <GoogleCallback />,
  },
  {
    path: "*",
    exact: true,
    component: () => <NotFoundPage />,
  },
];

export default routes;
