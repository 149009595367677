import LoadingIndicator from "assets/svg/loading-indicator";
import Button from "components/button/button";
import Modal from "components/global/modal";
import Input from "components/input/input";
import _ from "lodash";
import { useState } from "react";
import toast from "react-hot-toast";
import { BsTrash } from "react-icons/bs";
import { IoClose } from "react-icons/io5";
import Select from "components/select/select";

const captchaType = [
  { label: "reCaptcha", value: "recaptcha-2-checkbox" },
  { label: "hCaptcha", value: "hCaptcha" },
];

export default function ReCaptchaSettings({ hide, ...props }) {
  const { http } = global.services;
  const [state, setState] = useState({
    type: props?.type || "recaptcha-2-checkbox",
    name: props?.name || "",
    site_key: props?.site_key || "",
    secret_key: props?.secret_key || "",
  });
  const [formError, setFormError] = useState({});
  const [loading, setLoading] = useState(false);

  const handleSubmit = async () => {
    setLoading(true);
    let payload = _.clone(state);
    if (props?.id) {
      payload.id = props?.id;
    }
    try {
      if (props?.id) {
        const update = await http.put(`captchas/${props?.id}`, payload);
        if (update?.data?.success) {
          hide(update?.data?.message);
        }
      } else {
        const insert = await http.post("captchas", payload);
        if (insert?.data?.success) {
          hide(insert?.data?.message);
        }
      }
    } catch (error) {
      if (error.status === 422) {
        return setFormError((prev) => ({
          ...prev,
          ...error.data.data,
        }));
      }
      toast.error("It appears that something went wrong");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handeleDelete = async () => {
    setLoading(true);
    try {
      if (props?.id) {
        const _delete = await http.delete(`captchas/${props?.id}`);
        if (_delete?.data?.success) {
          hide(_delete?.data?.message);
        }
      }
    } catch (error) {
      if (error.status === 422)
        toast.error("It appears that something went wrong");
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === "name") {
      setState((prev) => ({ ...prev, [name]: value }));
    } else {
      setState((prev) => ({ ...prev, [name]: value.replace(/\s/g, "") })); // remove all whitespaces in value
    }

    setFormError((prev) => ({ ...prev, [name]: null }));
  };

  const Footer = () => {
    return (
      <div className="w-full flex items-center flex-wrap">
        <div className="w-4/12">
          {props?.id && (
            <button
              className="text-sm font-bold flex items-center justify-center"
              onClick={handeleDelete}
            >
              <BsTrash className="text-xl mr-2" />
              <span className="relative top-0.5">DELETE RECAPTCHA</span>
            </button>
          )}
        </div>
        <div className="relative flex flex-row items-center justify-end w-8/12 space-x-2">
          <Button
            key="cancel-btn"
            buttonName="CANCEL"
            buttonClass="bg-default rounded-full py-3 px-9"
            buttonType="default"
            buttonTextClass="text-black text-sm font-bold"
            onClick={() => hide()}
          />
          <Button
            key="save-btn"
            buttonName={props?.id ? "SAVE CHANGES" : "CREATE RECAPTCHA"}
            buttonClass="bg-primary rounded-full py-3 px-12"
            buttonType="primary"
            buttonTextClass="text-white text-sm font-bold"
            onClick={handleSubmit}
            disabled={loading}
            icon={
              loading && (
                <div className="absolute right-3 bottom-0 top-0 flex items-center">
                  <LoadingIndicator className="text-white w-5 h-5" />
                </div>
              )
            }
          />
        </div>
      </div>
    );
  };

  return (
    <Modal
      footer={<Footer />}
      width="35%"
      contentClassName="rounded-lg"
      wrapperClassName="overflow-visible"
      footerClass="border-t border-tabBorder"
    >
      <div className="flex items-start px-4">
        <div className="w-full">
          <h3 className="text-2xl leading-9" id="modal-title">
            reCAPTCHA Settings
          </h3>

          <label for="captcha" className="input-label-upper">
            Captcha Type
          </label>

          <Select
            options={captchaType}
            name="type"
            placeholder={"Captcha Type"}
            value={state.type}
            onChange={(e) =>
              setState((prev) => {
                return { ...prev, type: e.value };
              })
            }
          />
          {state.type === "recaptcha-2-checkbox" && (
            <p>
              We only support reCAPTCHA v2 checkbox keys.{" "}
              <a
                className="text-secondary"
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/recaptcha/admin/create"
              >
                Get your reCAPTCHA keys here.
              </a>
            </p>
          )}

          <div className="mt-4 relative">
            <div className="grid">
              <Input
                inputContainerClass="input-container"
                value={state.name}
                outsideLabel={"Label"}
                labelClass="mb-5"
                placeholder="Website name"
                name="name"
                type="text"
                onChange={handleChange}
                errorMessage={formError?.name}
                errorMessagePosition="bottom"
              />
              <Input
                inputContainerClass="input-container"
                value={state.site_key}
                outsideLabel="Site Key"
                labelClass="mb-5"
                name="site_key"
                type="text"
                onChange={handleChange}
                errorMessage={formError?.site_key}
                errorMessagePosition="bottom"
                maxLength={40}
              />
              <Input
                inputContainerClass="input-container"
                value={state.secret_key}
                outsideLabel="Secret Key"
                labelClass="mb-5"
                name="secret_key"
                type="text"
                onChange={handleChange}
                errorMessage={formError?.secret_key}
                errorMessagePosition="bottom"
                maxLength={40}
              />
            </div>
          </div>
        </div>

        <button className="absolute top-6 right-8" onClick={() => hide()}>
          <IoClose className="text-xl" />
        </button>
      </div>
    </Modal>
  );
}
