import React, { useEffect, useState } from "react";
import Layout from "layouts/layout";
import SearchIcon from "assets/svg/search-icon";
import { BiEdit } from "react-icons/bi";
import Input from "components/input/input";
import Button from "components/button/button";
import EmptyReCaptcha from "components/recaptcha/empty-recaptcha";
import RecaptchaTable from "components/recaptcha/recaptcha-table";
import useDebounce, { useDialogHook } from "utils/customhooks";
import ReCaptchaSettings from "components/recaptcha/recaptcha-create-modal";
import toast from "react-hot-toast";

const ReCaptchaPage = () => {
  const { http } = global.services;
  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState(false);
  const [list, setList] = useState([]);

  const filter = useDebounce(search, 500);
  const modal = useDialogHook(ReCaptchaSettings);

  const getCaptchaList = async () => {
    setLoading(true);
    try {
      const params = {
        limit: 100,
        search: search,
      };
      const result = await http.get(`captchas`, params);
      const data = result.data.data;
      setList(data);
    } catch (error) {
      setLoading(false);
      toast.error("It appears that something went wrong");
    } finally {
      setLoading(false);
    }
  };

  const upsertRecaptcha = (record = {}) => {
    modal(record, (result) => {
      if (result) {
        getCaptchaList();
        toast.success(result);
      }
    });
  };

  const columns = [
    {
      title: "Label",
      dataIndex: "name",
      key: "name",
      align: "left",
      render: (record) => (
        <div className="w-full flex justify-between items-center">
          <p className="text-base">{record.name}</p>
          <button onClick={() => upsertRecaptcha(record)}>
            <BiEdit className="text-2xl" />
          </button>
        </div>
      ),
    },
  ];

  useEffect(() => {
    getCaptchaList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const checkScroll = () => {
    let header = document.getElementById("sticky-header");
    let bound = header.getBoundingClientRect();
    let sticky = bound.top;

    if (sticky === 0) {
      header.classList.add("bg-white", "py-4", "border-b", "border-thBorder");
    } else {
      header.classList.remove(
        "bg-white",
        "py-4",
        "border-b",
        "border-thBorder"
      );
    }
  };

  return (
    <Layout
      title="Anti-Spam - reCAPTCHA"
      routeName="recaptcha"
      noPadding={true}
      scrollYPosition={checkScroll}
    >
      <div className="w-full py-8">
        {/* header */}
        <div className="w-full sticky top-0 z-10 " id="sticky-header">
          <div className="relative popout-wrapper mx-auto flex-1 px-7">
            <div className="w-full 1md:flex 1md:items-center 1md:justify-between 1md:flex-wrap">
              <div className="w-full 1md:w-1/2">
                <Input
                  placeholder="Search"
                  noLabelPaddingY="0.8rem"
                  value={search}
                  onChange={(e) => setSearch(e?.target?.value || "")}
                  inputIcon={<SearchIcon />}
                />
              </div>
              <div className="mt-2 1md:mt-0 ">
                <Button
                  buttonName="ADD CAPTCHA"
                  buttonTextClass="text-white text-sm font-bold"
                  buttonType="primary"
                  ariaLabel="Add CAPTCHA"
                  buttonClass="bg-primary ml-auto rounded-full py-3 px-12 text-center text-white"
                  onClick={() => upsertRecaptcha({})}
                />
              </div>
            </div>
          </div>
        </div>

        {/* content here */}
        <div className="relative mt-9 popout-wrapper mx-auto px-7">
          <RecaptchaTable
            columns={columns}
            dataSource={list}
            noData={<EmptyReCaptcha />}
            paginate={false}
            loading={loading}
          />
        </div>
      </div>
    </Layout>
  );
};

export default ReCaptchaPage;
