import Modal from "components/global/modal";
import { INITIAL_BILLING_PERIOD } from "configs/api";
import parseUrl from "parse-url";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { FaTimes } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import { useHistory } from "react-router-dom";
import { sendClickCheckout } from "utils/gtm-triggers";
import { getTrackDeskCid } from "utils/trackdesk";
import PricingTable from "./component/pricing-table";

const currency = "aud";

export default function PlanModal({ hide, userData, setUserData }) {
  const { http, localstorage } = global.services;
  const history = useHistory();
  const [planLoading, setPlanLoading] = useState(true);
  const [highlightedPrice, setHighLightedPrice] = useState(null);
  const [isLoadingCheckout, setIsLoadingCheckout] = useState(false);
  const [rawPlans, setRawPlans] = useState([]);
  const [plans, setPlans] = useState([]);
  const [billing, setBilling] = useState(INITIAL_BILLING_PERIOD);
  const [trialPeriod, setTrialPeriod] = useState("14 day");
  const [trialDays, setTrialDays] = useState(14);
  const [trialCode, setTrialCode] = useState(null);

  const getPlans = async () => {
    setPlanLoading(true);
    try {
      const result = await http.post("subscriptions/plans");
      const data = result.data.data;
      const enabledTrial = result.data.enabled_trial;

      if (data && Array.isArray(data)) {
        setRawPlans(data);
      }
      if (enabledTrial) {
        const totalDays = localstorage.get("trialDays").value;
        const code = localstorage.get("trialCode").value;

        if (code) {
          setTrialCode(code);
          setTrialPeriod(`${totalDays} day`);
          setTrialDays(totalDays);
        }
      }
    } catch (error) {
      if (error.status === 422)
        toast.error("It appears that something went wrong");
    } finally {
      setPlanLoading(false);
    }
  };

  const resetTrialCode = () => {
    // reset trial code and days
    localstorage.remove("trialDays");
    localstorage.remove("trialCode");
    setTrialDays(14);
    setTrialCode(null);
  };

  const handleSubmit = async (priceId) => {
    setIsLoadingCheckout(true);

    try {
      if (!userData.user.event_data) {
        http
          .put("ga4-events", {
            event_data: ["checkoutButtonClick"],
          })
          .then((response) => {
            sendClickCheckout();
            setUserData({ ...userData, user: response.data.data });
          });
      }
      const resp = await global.services.http.post("subscriptions", {
        client_reference_id: getTrackDeskCid(),
        price: priceId,
        account_page: false,
        trialDays: trialDays,
        trialCode: trialCode,
        billing: billing,
      });

      if (!resp.data.success) {
        throw new Error(resp.data.message);
      }

      window.location.href = resp.data.url;
    } catch (e) {
      console.error(e);
      toast.error("Failed to open checkout. Please contact support.");
      setIsLoadingCheckout(false);
    }
  };

  const proceedLogout = async () => {
    try {
      // reset trial code and days if any
      resetTrialCode();

      const parts = parseUrl(window.location.href);
      const pathname = parts.pathname || "";
      const search = parts.search || "";
      const hash = parts.hash || "";

      await http.post("auth/logout");
      localstorage.remove("user_data"); // Remove user data on storage
      setUserData(null); // Remove user data on redux
      history.push(`${pathname + search + hash}`); // Redirect to current page
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getPlans();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!rawPlans) {
      return;
    }

    const plans = rawPlans
      .map((obj) => {
        const price = obj.prices.find(
          (p) => p.currency === currency && p.recurring.interval === billing
        );
        return { ...obj, price };
      })
      .filter((obj) => !!obj.price)
      .sort((a, b) => a.price.unit_amount - b.price.unit_amount);
    setPlans(plans);
    const recommended = plans.find((x) => x.recommended);
    if (recommended) {
      setHighLightedPrice(recommended.price.id);
    } else if (plans.length > 0) {
      setHighLightedPrice(plans[0].price.id);
    }
  }, [rawPlans, billing]);

  return (
    <Modal
      width={
        plans.length < 3
          ? "w-18/20 2md:w-14/20 3md:w-10/20"
          : "w-18/20 2md:w-16/20 3md:w-12/20"
      }
      responsive={true}
      contentClassName="rounded-lg overflow-auto custom-scroll"
      wrapperClassName="overflow-visible"
      footerClass="border-t border-tabBorder"
      footerDefaultClass="" //leave this empty
    >
      <div className="w-full p-4">
        <div className="w-full">
          <div className="w-full flex justify-between">
            <div>
              <h3 className="text-2xl font-medium" id="modal-title">
                Welcome to ConversionCow!
              </h3>
              <p className="opacity-70">Select a plan to get started.</p>
            </div>
            <div>
              <button className="p-2" onClick={proceedLogout}>
                <FaTimes />
              </button>
            </div>
          </div>
          <div className="mt-8">
            <div className="mb-4 w-full max-w-sm mx-auto p-1 grid grid-cols-2 gap-1 rounded-full bg-billing">
              <button
                className={`px-2 py-2 text-sm text-center font-bold rounded-full ${
                  billing === "month" ? "bg-white text-black" : "text-textGray"
                }`}
                onClick={() => {
                  setBilling("month");
                }}
              >
                MONTHLY
              </button>
              <button
                className={`px-2 py-2 text-sm text-center font-bold rounded-full ${
                  billing === "year" ? "bg-white text-black" : "text-textGray"
                }`}
                onClick={() => {
                  setBilling("year");
                }}
              >
                ANNUAL
                <span className="pl-2.5 text-secondary">SAVE 20%</span>
              </button>
            </div>
            {planLoading ? (
              <Skeleton width={"100%"} height={350} />
            ) : (
              <div
                className={`gap-5 ${
                  isLoadingCheckout
                    ? " opacity-50 animate-pulse pointer-events-none"
                    : ""
                } ${
                  plans.length < 2
                    ? "2md:w-1/2 2md:mx-auto"
                    : plans.length < 3
                    ? "grid 2md:grid-cols-2"
                    : "grid 2md:grid-cols-3"
                }`}
              >
                {plans.map((obj, index) => {
                  return (
                    <PricingTable
                      onClick={() => {
                        handleSubmit(obj.price.id);
                      }}
                      key={index}
                      title={obj.name}
                      currency={obj.price.currency || ""}
                      interval={obj.interval}
                      subTitle={obj.description}
                      price={obj.price.unit_amount / 100}
                      type={`${billing}`}
                      buttonName={"Start your free trial"}
                      selected={highlightedPrice === obj.price.id}
                      list={obj.features?.map((x) => x.name)}
                      footerText={`Free for ${trialPeriod}s.`}
                      small
                    />
                  );
                })}
              </div>
            )}
          </div>
          <div className="mt-8">
            <ul className="list-disc ml-4">
              <li className="mb-1 font-bold">
                Enjoy a {trialPeriod} free trial and explore our platform
                risk-free. Cancel anytime.
              </li>
              <li className="mb-1">
                Powered by Stripe to offer you a seamless and secure payment
                experience.
              </li>
              <li className="mb-0">
                No need to commit to a specific plan, upgrade or downgrade
                anytime.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </Modal>
  );
}
