import LoadingIndicator from "assets/svg/loading-indicator";
import NotificationMarkSVG from "assets/svg/notification-mark";
import Button from "components/button/button";
import CustomCheckbox from "components/checkbox/custom-checkbox";
import Divider from "components/divider/divider";
import GoogleAuthButton from "components/google-auth-button/google-auth-button";
import Input from "components/input/input";
import { API_ENDPOINT, RECAPTCHA_KEY } from "configs/api";
import { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import toast from "react-hot-toast";
import { FaChevronRight } from "react-icons/fa";
import { connect } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import Popup from "reactjs-popup";
import userActions from "redux/modules/user/actions";
import { getTrackDeskCid } from "utils/trackdesk";
import "../../assets/css/pages/sign-up.css";
import PasswordValidation from "./password-validation";
import VerificationContent from "./verification-content";

const PasswordNotification = ({ passwordValidation }) => {
  return (
    <div className="relative">
      Password {` `}
      <Popup
        trigger={(open) => (
          <button
            className="button"
            onClick={(e) => {
              e.preventDefault();
            }}
          >
            <NotificationMarkSVG />{" "}
          </button>
        )}
        on={["hover", "focus"]}
        position="right center"
        closeOnDocumentClick
      >
        <PasswordValidation passwordValidation={passwordValidation} />
      </Popup>
    </div>
  );
};

const SignUp = ({
  user,
  showLoginModal = {}, // when guest decides to use login process
  modal = false, // for modal
}) => {
  const history = useHistory();
  const { http, localstorage } = global.services;
  const [isVerification, setIsVerification] = useState(false);
  const [recaptcha, setRecaptcha] = useState(null);
  const [recapthcaError, setRecapthcaError] = useState(null);
  const [state, setState] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    password_confirmation: "",
    showPassword: false,
    showConfirmPassword: false,
    terms: false,
    loading: false,
    validationErrors: null,
    recaptchaToken: "",
    optin: false,
    trialCode: null,
  });
  const [passwordValidation, setPasswordValidation] = useState({
    passwordHasUpperCaseLetter: false,
    passwordHasLowerCaseLetter: false,
    passwordHasNumber: false,
    passwordHasEightCharacters: false,
  });

  const [trialCode, setTrialCode] = useState(null);
  const [trialDays, setTrialDays] = useState(14);

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    if (name === "password") {
      const hasUpperCase = /[A-Z]/.test(value);
      const hasLowerCase = /[a-z]/.test(value);
      const hasEightCharacters = value.length >= 8;
      const hasNumber = /\d/.test(value);

      setPasswordValidation((prevState) => ({
        ...prevState,
        passwordHasUpperCaseLetter: hasUpperCase,
        passwordHasLowerCaseLetter: hasLowerCase,
        passwordHasEightCharacters: hasEightCharacters,
        passwordHasNumber: hasNumber,
      }));
    }

    setState((prev) => ({
      ...prev,
      [name]: value,
      validationErrors: { ...state.validationErrors, [name]: null },
    }));
  };

  const handleCreateAccount = async (e) => {
    e.preventDefault();

    if (RECAPTCHA_KEY) {
      if (!recaptcha) {
        return setRecapthcaError("reCaptcha is required.");
      }

      state.recaptchaToken = recaptcha;
    }

    setState((prev) => ({
      ...prev,
      loading: true,
    }));

    if (trialCode) {
      state.trialCode = trialCode;
    }

    try {
      await http.post("auth/register", {
        ...state,
        trackdesk_cid: getTrackDeskCid(),
      });
      setIsVerification(true);
      setState((prev) => ({ ...prev, loading: false }));
    } catch (error) {
      setState((prev) => ({ ...prev, loading: false }));
      if (error.status === 422)
        return setState((prev) => ({
          ...prev,
          validationErrors: error.data,
        }));

      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : error?.data?.message || "It appears that something went wrong"
      );
    }
  };

  const resendEmailVerification = async () => {
    setState((prev) => ({ ...prev, loading: true }));

    try {
      const params = { email: state.email };
      await http.post("auth/resend-email-verification", params);
    } catch (error) {
      toast.error(
        error.status === 401
          ? "Invalid Credentials"
          : error?.data?.message || "It appears that something went wrong"
      );
    } finally {
      setState((prev) => ({ ...prev, loading: false }));
    }
  };

  const handleUpdateCheckbox = (e) => {
    const isChecked = e.target.checked;
    setState({ ...state, optin: isChecked });
  };

  const handleGoogleSignup = () => {
    localstorage.set("optin", state.optin);
    window.location.href = `${API_ENDPOINT}/auth/login/google`;
  };

  const onChangeCaptcha = (value) => {
    setRecaptcha(value);
  };

  const handleTrialLinkCode = () => {
    const queryParameters = new URLSearchParams(window.location.search);
    const trialCode = queryParameters.get("xt") ?? null;
    const trialDays = queryParameters.get("td") ?? null;

    if (trialCode) {
      setTrialCode(trialCode);
    }
    if (trialDays && !isNaN(trialDays)) {
      setTrialDays(trialDays);
    }
  };

  useEffect(() => {
    if (user && !modal) return history.replace("/dashboard");
    localstorage.set("optin", false); //reset optin state

    handleTrialLinkCode();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="page-wrapper">
      {isVerification ? (
        <VerificationContent
          loading={state.loading}
          resend={resendEmailVerification}
        />
      ) : (
        <>
          <h2 className="column-title pb-2">
            Start Your {trialDays}-Day Free Trial
            <br />
            <small>Create Your Account</small>
          </h2>

          <form className="column-container" id="signup-page-component">
            <Input
              inputContainerClass="input-container"
              showInputValidation={true}
              value={state.email}
              autoFocus={true}
              validationPassed={/^\w+([.]?\w+)*@\w+([.]?\w+)*(\.\w{2,3})+$/.test(
                state.email
              )}
              name="email"
              outsideLabel="Email Address"
              outsideLabelClass="text-[#616162] text-xs"
              type="text"
              onChange={handleInputChange}
              errorMessage={state.validationErrors?.email}
            />
            <Input
              inputContainerClass="input-container"
              showInputValidation={true}
              value={state.password}
              outsideLabel={
                <PasswordNotification passwordValidation={passwordValidation} />
              }
              outsideLabelClass="text-[#616162] text-xs"
              name="password"
              validationPassed={Object.values(passwordValidation).every(
                (item) => item === true
              )}
              type={state.showPassword ? "text" : "password"}
              onChange={handleInputChange}
              errorMessage={state.validationErrors?.password}
            />
            <Input
              inputContainerClass="input-container"
              showInputValidation={true}
              value={state.password_confirmation}
              outsideLabel="Confirm Password"
              outsideLabelClass="text-[#616162] text-xs"
              name="password_confirmation"
              validationPassed={state.password === state.password_confirmation}
              type={state.showConfirmPassword ? "text" : "password"}
              onChange={handleInputChange}
              errorMessage={state.validationErrors?.password_confirmation}
            />

            <div className={`leading-7 px-1`}>
              <CustomCheckbox
                label={
                  <div>
                    {`Yes, I want to receive the latest updates, news, and offers via email. `}
                  </div>
                }
                labelClass="text-xs font-light"
                name={"opt-in"}
                checked={state.opt}
                onChange={(e) => handleUpdateCheckbox(e)}
              />
            </div>
            <div className="text-xs font-light mb-3 leading-7 px-1 text-center">
              {`By signing up, you agree to our `}
              <a
                href="https://www.conversioncow.com/terms-of-use/"
                target="_blank"
                rel="noreferrer"
                className="text-secondary font-medium underline"
              >
                Terms of Use
              </a>{" "}
              and{" "}
              <a
                href="https://www.conversioncow.com/privacy-policy/"
                target="_blank"
                rel="noreferrer"
                className="text-secondary font-medium underline"
              >
                Privacy Policy.
              </a>
            </div>

            {RECAPTCHA_KEY && (
              <div
                style={{
                  width: "100%",
                  marginTop: "15px",
                  marginBottom: "15px",
                }}
                className="px-1"
              >
                <ReCAPTCHA sitekey={RECAPTCHA_KEY} onChange={onChangeCaptcha} />
                {recapthcaError && (
                  <p className="text-red text-xs mt-1">{recapthcaError}</p>
                )}
              </div>
            )}
            <div className="px-1">
              <Button
                buttonName="Create Account"
                buttonClass="relative py-3 bg-secondary"
                buttonType="secondary"
                buttonWidth="100%"
                onClick={handleCreateAccount}
                type="submit"
                disabled={
                  state.loading ||
                  !Object.values(passwordValidation).every(
                    (item) => item === true
                  )
                }
                icon={
                  <div className="absolute right-3 bottom-0 top-0 flex items-center">
                    {state.loading ? (
                      <LoadingIndicator className="text-white w-5 h-5" />
                    ) : (
                      <FaChevronRight className="text-white text-base 2md:text-lg" />
                    )}
                  </div>
                }
              />
            </div>

            <Divider text="or" />

            <GoogleAuthButton
              onClick={handleGoogleSignup}
              title="Sign up with Google"
            />

            <div className="mt-8">
              <div className="no-account-text text-xs">
                You already have an account?{" "}
                <Link
                  to={"/login"}
                  className="no-account-link font-medium underline"
                >
                  Login
                </Link>
              </div>
            </div>
          </form>
        </>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    user: state.user.userData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    setUserData: (params) => {
      dispatch(userActions.setUserData(params));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
