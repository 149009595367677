import "assets/css/components/campaign-details.css";
import Select from "components/select/select";
import { Context } from "context/context";
import _ from "lodash";
import { useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { IoInformationCircleOutline } from "react-icons/io5";

export const ReCaptcha = () => {
  const { http } = global.services;
  const { campaign, setCampaign } = useContext(Context);
  const { captcha } = campaign;
  const [captchaList, setCaptchaList] = useState([]);
  const [recaptcha, setRecaptcha] = useState({
    label: "",
    value: "",
  });

  const getReCaptchaList = async () => {
    try {
      const result = await http.get(`captchas`, {
        limit: 100,
      });

      const data = result.data.data;
      if (data) {
        let payload = [];
        data.forEach((obj) => {
          payload.push({ label: obj.name, value: obj.id });
        });
        setCaptchaList(payload);
      }
    } catch (error) {
      if (error.status === 422)
        toast.error("It appears that something went wrong");
    }
  };

  const onChangeRecaptcha = async (e) => {
    setRecaptcha(e);
    try {
      if (campaign.id) {
        const params = {
          id: campaign.id,
          captcha_id: e.value,
        };
        const res = await http.put(`campaigns/${campaign.id}`, params);
        if (!_.isEmpty(res.data.data)) {
          toast.success("Campaign Recaptcha Updated");
          setCampaign((prev) => ({ ...prev, ...res.data.data }));
        }
      }
    } catch (e) {
      console.error(e);
      toast.error("It appears that something went wrong");
    }
  };

  const onClearValue = () => {
    onChangeRecaptcha({
      label: "",
      value: null,
    });
  };

  useEffect(() => {
    if (captcha) {
      setRecaptcha({
        label: captcha.name,
        value: captcha.id,
      });
    }
  }, [captcha]);

  useEffect(() => {
    getReCaptchaList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="settings-wrapper">
      <div className="w-full">
        <h1 className="mr-3 text-2xl">reCAPTCHA</h1>
      </div>
      <div className="settings-time-container">
        <div className="w-full 2md:w-1/2">
          <Select
            clearable={true}
            options={captchaList}
            placeholder="Select reCAPTCHA"
            emptyPlaceholder={
              <div className="w-full text-center">
                <p className="text-xl flex items-center justify-center">
                  <IoInformationCircleOutline className="text-3xl mr-2 opacity-70" />
                  <span>No reCAPTCHA keys yet</span>
                </p>
                <p className="text-sm">
                  <span className="text-black text-opacity-70">
                    Do you want to add your first reCAPTCHA?
                  </span>
                  &nbsp;
                  <a className="text-secondary" href="/recaptcha">
                    Add Now
                  </a>
                </p>
              </div>
            }
            value={recaptcha?.label}
            selected={recaptcha}
            onChange={(e) => onChangeRecaptcha(e)}
            onClearValue={onClearValue}
          />
        </div>
      </div>
    </div>
  );
};

export default ReCaptcha;
